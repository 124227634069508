import React from "react"
import dayjs from "dayjs"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { navigation } from './shared'
import { InboxIcon, PhoneIcon } from "@heroicons/react/solid"

const now = dayjs()

const Footer = () => (
	<footer className="text-white bg-charcoal py-20">
  	<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
  		<div className="flex flex-wrap justify-center lg:justify-end">
        <div className="w-full lg:w-1/2 flex flex-col items-center lg:items-start pb-10">
          <h3 className="text-xl font-semibold uppercase pb-4">Navigation</h3>
          <ul>
          { navigation.map(({ name, href }, key) => (
            <li key={key} className="py-1 text-center lg:text-left"><Link className="hover:underline" to={href}>{name}</Link></li>
          )) }
            <li key="admin" className="py-1 text-center lg:text-left"><a className="hover:underline" href="/#admin">Log in</a></li>
          </ul>
        </div>
  			<div className="w-full lg:w-1/2 flex flex-col items-center lg:items-end">
  				<div className="py-1 flex items-center">
  					<div>Built with &nbsp;</div>
  					<div>
  						<img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xMS41IC0xMC4yMzE3NCAyMyAyMC40NjM0OCI+CiAgPHRpdGxlPlJlYWN0IExvZ288L3RpdGxlPgogIDxjaXJjbGUgY3g9IjAiIGN5PSIwIiByPSIyLjA1IiBmaWxsPSIjNjFkYWZiIi8+CiAgPGcgc3Ryb2tlPSIjNjFkYWZiIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIi8+CiAgICA8ZWxsaXBzZSByeD0iMTEiIHJ5PSI0LjIiIHRyYW5zZm9ybT0icm90YXRlKDYwKSIvPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgPC9nPgo8L3N2Zz4K" alt="ReactJS Logo" height="48" width="48" />
  					</div>
  					<div>&nbsp; ReactJS</div>
  				</div>
	  			<div className="py-1 flex items-center">
	  				<div>Shipped with&nbsp;</div>
	  				<div><StaticImage src="../images/gatsby-icon.png" alt="GatsbyJS Logo" /></div>
	  				<div>atsbyJS</div>
	  			</div>
	  			<div className="py-1 flex items-center">
	  				<div>Hosted on &nbsp;</div>
	  				<div><img src="https://api.netlify.com/api/v1/badges/305942f0-306f-4246-9706-db6b4b00a390/deploy-status" alt="Deploy status badge" /></div>
	  			</div>
	  			<div className="py-2 flex"><InboxIcon className="h-6 w-6" aria-hidden="true" />&nbsp;<a href="mailto:csobeck@pyroclasti.cloud" className="underline">csobeck@pyroclasti.cloud</a></div>
	  			<div className="py-2 flex"><PhoneIcon className="h-6 w-6" aria-hidden="true" />&nbsp;<a href="tel:12693121723" className="underline">269.312.1723</a></div>
	  			<div className="py-2"><strong className="font-semibold">&copy;{now.format("YYYY")}</strong> <a href="/" className="underline">Pyroclastic Cloud</a></div>
	  		</div>
  		</div>
  	</div>
  </footer>
)

export default Footer